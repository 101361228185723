<template>
  <v-row>
    <v-col cols="12">
      <pibot-form-fieldset>
        <template #legend>
          Dropped Object Assessment
        </template>

        <v-row>
          <v-col
            v-for="(field, i) in fields"
            :key="`form-field-${i}`"
            cols="12"
            md="6"
            lg="4"
          >
            <pibot-form-field
              :field="field"
              :loading="loading"
              :readonly="field.readonly || loading"
              :disabled="field.disabled"
              :placeholder="field.label"
              :error-messages="errors[field.model]"
              @change="onChange"
              outlined
            />
          </v-col>
        </v-row>
      </pibot-form-fieldset>
    </v-col>
  </v-row>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { $gte } from '@/utils/validations'
import assessment from '@/apps/eprs/utils/mixins/assessment.mixin'

const FORM = {
  shape: undefined,
  mass: undefined,
  length: undefined,
  width: undefined,
  height: undefined,
  area: undefined,
  volume: undefined,
  'drag coefficient': undefined,
  'added mass coefficient': undefined
}

export default {
  name: 'pibot-eprs-dropped-object-form',
  mixins: [assessment, validationMixin],
  components: {
    'pibot-form-fieldset': () => import('@/components/form-components/Fieldset'),
    'pibot-form-field': () => import('@/components/form-components/Field')
  },
  validations: {
    form: {
      mass: { required, $gte: (v) => $gte(v, 1) },
      shape: { required },
      length: { $gte: (v) => v === undefined || $gte(v, 0) },
      width: { $gte: (v) => v === undefined || $gte(v, 0) },
      height: { $gte: (v) => v === undefined || $gte(v, 0) },
      area: { $gte: (v) => v === undefined || $gte(v, 0) },
      volume: { $gte: (v) => v === undefined || $gte(v, 0) },
      'drag coefficient': { $gte: (v) => v === undefined || $gte(v, 0) },
      'added mass coefficient': { $gte: (v) => v === undefined || $gte(v, 0) }
    }
  },
  data: () => ({
    form: FORM
  }),
  computed: {
    fields () {
      return [
        {
          type: 'select',
          model: 'shape',
          value: this.form.shape,
          label: 'Object Type *',
          prefix: '',
          suffix: '',
          icon: '',
          required: true,
          options: [
            {
              text: 'Container',
              value: 'container'
            }
          ]
        },
        {
          type: 'number',
          model: 'mass',
          value: this.form.mass,
          label: 'Mass *',
          min: 0,
          prefix: '',
          suffix: 'kg',
          icon: '',
          required: true
        },
        {
          type: 'number',
          model: 'length',
          value: this.form.length,
          label: 'Length',
          min: 0,
          prefix: '',
          suffix: 'm',
          icon: '',
          required: false
        },
        {
          type: 'number',
          model: 'width',
          value: this.form.width,
          label: 'Width',
          min: 0,
          prefix: '',
          suffix: 'm',
          icon: '',
          required: false
        },
        {
          type: 'number',
          model: 'height',
          value: this.form.height,
          label: 'Height',
          min: 0,
          prefix: '',
          suffix: 'm',
          icon: '',
          required: false
        },
        {
          type: 'number',
          model: 'area',
          value: this.form.area,
          label: 'Area',
          min: 0,
          prefix: '',
          suffix: 'm²',
          icon: '',
          required: false
        },
        {
          type: 'number',
          model: 'volume',
          value: this.form.volume,
          label: 'Volume',
          min: 0,
          prefix: '',
          suffix: 'm³',
          icon: '',
          required: false
        },
        {
          type: 'number',
          model: 'drag coefficient',
          value: this.form['drag coefficient'],
          label: 'Drag Coefficient',
          min: 0,
          prefix: '',
          suffix: '',
          icon: '',
          required: false
        },
        {
          type: 'number',
          model: 'added mass coefficient',
          value: this.form['added mass coefficient'],
          label: 'Added Mass Coefficient',
          min: 0,
          prefix: '',
          suffix: '',
          icon: '',
          required: false
        }
      ]
    },
    errors () {
      const errors = {}

      for (const field of Object.values(this.fields)) {
        if (!this.$v.form[field.model].$invalid) {
          delete errors[field.model]
          continue
        }

        const messages = this.$v.form[field.model].$flattenParams().map(({ name }) => {
          if (name.includes('required')) return `${field.label} is required.`
          // else if (name.includes('gte')) return `${field.label} must be greater or equal to ${field.min}.`
          else if (name.includes('mustBeNumber')) return `${field.label} must be a number.`
          else if (name.includes('mustBeString')) return `${field.label} must be text.`
          else return undefined
        }).filter(v => !!v)

        errors[field.model] = messages
      }

      return errors
    }
  },
  async beforeMount () {
    this.reset()
    await this.init()
    this.validate()
  },
  methods: {
    onChange (v) {
      const update = { ...v }
      if (typeof update.value === 'number' && isNaN(update.value)) update.value = undefined
      this.$set(this.form, update.field.model, update.value)
      this.$v.form[v.field.model].$touch()
    },
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    reset () {
      this.form = Object.assign({}, FORM)
      this.$v.$reset()
    }
  }
}
</script>
